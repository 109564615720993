import logo from './logo.svg';
import { useState } from 'react';
import './App.css';
import CryptoJS from 'crypto-js';


function App() {
  const [inputvalue, setinputvalue] = useState("")
  const [yes, setYes] = useState("");

  const hashit = () => {
    setYes(CryptoJS.SHA256(inputvalue).toString());
  }

  if (yes === '95474ef85f37ce52c279f5c5b443859b5f339ac1cd2b0bfac23f87b6c2d8aa32') {
    return (
      <div className="App">
        <header className="App-header">

          <iframe width="1080" height="700" src="https://www.youtube.com/embed/NgG25BL8g7M?si=-za4Ynt2jggsgrIp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </header>
      </div>
    )
  }
  return (
    <div className="App">
      <header className="App-header">

        <div style={{ fontSize: "32px" }}>CODE MAPS</div>
        <div style={{ fontSize: "16px", marginTop: "12px", marginBottom: "8px" }}>Enter Code</div>
        <input value={inputvalue} onChange={(e) => {
          setinputvalue(e.target.value)
        }}></input>
        <button onClick={hashit}>Enter</button>
      </header>
    </div>
  );
}

export default App;
